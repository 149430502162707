import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../core/Footer';
import TextBox from '../core/TextBox';
import Header from '../core/Header';
import styles from './VaillantPasswordReset.module.css';
import favicon from '../assets/vaillant-favicon.png';

const VaillantPasswordReset = () => {
  return (
    <div className={styles.content}>
      <Helmet
        title="Password Reset: myVAILLANT"
        meta={[{ name: "charSet", content: "utf-8" }]}
        link={[{ rel: "shortcut icon", type: "image/png", href: `${favicon}`}]}
      />
      <Header />
      <main className={styles.main}>
        <TextBox brand='vaillant' />
      </main>
      <Footer brand='vaillant' />
    </div>
  );
}

export default VaillantPasswordReset;