import React from 'react';
import  './Header.css';

const Header = () => {
  return (
    <div className='header'>
      <img src={require('../assets/vaillant-logo.png')} alt='Vaillant' />
    </div>
  );
}

export default Header;