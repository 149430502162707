import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../core/Footer';
import TextBox from '../core/TextBox';
import styles from './SdbgPasswordReset.module.css';
import favicon from '../assets/sdbg-favicon.png';

const SdbgPasswordReset = () => {
  return (
    <div className={styles.content}>
      <Helmet
        title="Password Reset: MiGo Link"
        meta={[{ name: "charSet", content: "utf-8" }]}
        link={[{ rel: "shortcut icon", type: "image/png", href: `${favicon}`}]}
      >
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <main className={styles.main}>
        <TextBox brand='sdbg' />
      </main>
      <Footer brand='sdbg' />
    </div>
  );
}

export default SdbgPasswordReset;