import React from 'react';
import './Footer.css';

interface FooterProps {
  brand: 'sdbg' | 'vaillant'
}

const Footer = ({brand}: FooterProps) => {
  return (
    <div className={`footer ${brand}-footer`}>
      <p>
        &copy; {new Date().getFullYear()} Vaillant GmbH
        <br />
        Berghauser Str. 40 , D-42859 Remscheid, Germany.
      </p>
    </div>
  );
}

export default Footer;