import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import SdbgPasswordReset from './pages/SdbgPasswordReset';
import VaillantPasswordReset from './pages/VaillantPasswordReset';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/sdbg" element={<SdbgPasswordReset />} />
        <Route path="/vaillant" element={<VaillantPasswordReset />} />
				<Route
					path="*"
					element={<Navigate to={`/${process.env.REACT_APP_BRAND}`} />}
				/>
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
