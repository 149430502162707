import React from 'react';
import { MobileView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import './TextBox.css';

interface TextBoxProps {
  brand: 'sdbg' | 'vaillant'
}

const TextBox = ({brand}: TextBoxProps) => {
  const { t } = useTranslation();

  let color = '#cc0033';
  let link = process.env.REACT_APP_OPEN_MIGO_LINK_APP;

  if (brand === 'vaillant') {
    color = '#00917e';
    link = process.env.REACT_APP_OPEN_MYVAILLANT_APP;
  }
  
  return (
    <div className={`box ${brand}-box`}>
      <h2 style={{color}}>{t('signin.resetPassword.confirmationPage.head')}</h2>
      <p>{t('signin.resetPassword.confirmationPage.body')}</p>
      <MobileView>
        <a className='link' style={{backgroundColor: color}} href={link}>
          Back to the app
        </a>
      </MobileView>
    </div>
  );
}

export default TextBox;