import i18next, { Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './translations/en.json';
import en_GB from './translations/en_GB.json';
import bs_BA from './translations/bs_BA.json';
import hr from './translations/hr.json';
import cs from './translations/cs.json';
import da from './translations/da.json';
import nl_BE from './translations/nl_BE.json';
import nl_NL from './translations/nl_NL.json';
import fi from './translations/fi.json';
import fr_BE from './translations/fr_BE.json';
import fr_CH from './translations/fr_CH.json';
import fr_FR from './translations/fr_FR.json';
import de_AT from './translations/de_AT.json';
import de_CH from './translations/de_CH.json';
import de_DE from './translations/de_DE.json';
import el from './translations/el.json';
import hu from './translations/hu.json';
import it_CH from './translations/it_CH.json';
import it_IT from './translations/it_IT.json';
import no from './translations/no.json';
import pl from './translations/pl.json';
import pt from './translations/pt.json';
import sr from './translations/sr.json';
import sk from './translations/sk.json';
import sl from './translations/sl.json';
import es from './translations/es.json';
import sv from './translations/sv.json';
import bg from './translations/bg.json';
import et from './translations/et.json';
import lt from './translations/lt.json';
import lv from './translations/lv.json';
import ro from './translations/ro.json';
import tr from './translations/tr.json';

const translations: Resource = {
  'en-GB': { ...en_GB },
  'de-DE': { ...de_DE },
  'es-ES': { ...es },
  'nl-NL': { ...nl_NL },
  'pl-PL': { ...pl },
  'fr-BE': { ...fr_BE },
  'nl-BE': { ...nl_BE },
  'de-AT': { ...de_AT },
  'it-IT': { ...it_IT },
  'fr-FR': { ...fr_FR },
  'nb-NO': { ...no },
  'nn-NO': { ...no },
  'bs-BA': { ...bs_BA },
  'de-CH': { ...de_CH },
  'it-CH': { ...it_CH },
  'fr-CH': { ...fr_CH },
  'hr-HR': { ...hr },
  'sr-RS': { ...sr },
  'sl-SI': { ...sl },
  'sk-SK': { ...sk },
  'hu-HU': { ...hu },
  'cs-CZ': { ...cs },
  'fi-FI': { ...fi },
  'sv-SE': { ...sv },
  'da-DK': { ...da },
  'el-GR': { ...el },
  'pt-PT': { ...pt },
  'bg-BG': { ...bg },
  'et-EE': { ...et },
  'lt-LT': { ...lt },
  'lv-LV': { ...lv },
  'ro-RO': { ...ro },
  'tr-TR': { ...tr },
  en: { ...en },
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: {
      escapeValue: false
    },
    resources: {
      ...translations,
    },
    fallbackLng: ['en'],
    defaultNS: 'translation',
    detection: {
      order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain']
    }
  });

export default i18next;